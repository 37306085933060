import React, { Component } from "react";
import Navbar from "../components/navbar";
import Locate from "../assets/img/Vector3.png";
// import Phone from "../assets/img/Vector4.png";
import Mail from "../assets/img/Vector5.png";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let INPUT = "";

let EXTRA_NOTE_USER =
  "We received your request. One of our volunteers will contact you within 48 hours.\n\nkind regards, Benevolence.";
let SUBJECT_USER = "Request received";

let SUBJECT_ADMIN = "New request received";
let EXTRA_NOTE_ADMIN = "New request for aid";
let ADMIN_EMAIL = "info@benevolence.care";

export default class contact extends Component {
  state = {
    fname: "",
    lname: "",
    email: "",
    need: "",
    phoneNo: "",
    message: "",
    selectedMenu: "",
  };

  componentDidMount() {
    const { selectedMenu } = this.props.location.state;
    INPUT = selectedMenu;
    this.setState({ need: selectedMenu });
  }

  sendRequest = () => {
    // e.preventDefault();

    const { fname, lname, email, message, need, phoneNo } = this.state;

    if (!fname) {
      alert("First name is required");
      return;
    }

    if (!lname) {
      alert("Last name is required");
      return;
    }

    if (!email) {
      alert("Email is required");
      return;
    }

    if (!phoneNo) {
      alert("Phone number is required");
      return;
    }

    if (!need) {
      alert("Please specify a need");
      return;
    }

    if (!message) {
      alert("Message is required");
      return;
    }

    this.notify("Sending, Please wait..");

    axios({
      method: "POST",
      url: "https://us-central1-benevolence-b9aaa.cloudfunctions.net/sendMail",
      data: {
        name: fname + " " + lname,
        email,
        need,
        message,
        phoneNo,
        destination: email,
        subject: SUBJECT_USER,
        extraNote: EXTRA_NOTE_USER,
      },
    }).then((response) => {
      console.log("Email response: ", response);
      this.setState(
        {
          loading: false,
          fname: "",
          lname: "",
          email: "",
          phoneNo: "",
          message: "",
        },
        () => {
          this.notify(
            "We received your request. One of our volunteers will contact you within 48 hours."
          );
        }
      );
    });

    axios({
      method: "POST",
      url: "https://us-central1-benevolence-b9aaa.cloudfunctions.net/sendMail",
      data: {
        name: fname + " " + lname,
        email,
        need,
        message,
        phoneNo,
        destination: ADMIN_EMAIL,
        subject: SUBJECT_ADMIN,
        extraNote: EXTRA_NOTE_ADMIN,
      },
    });
  };
  notify = (message) => toast(message);

  render() {
    return (
      <section className="bg-grey">
        <ToastContainer />

        <header>
          <div className="contact">
            <div className="container">
              <Navbar />
              <div className="row py-5">
                <div className="col-lg-6 col-sm-12 text-white my-auto">
                  <h3 className="font64 font-bold text-white line-height">
                    Contact
                    <br /> Us
                  </h3>
                  <p className="font18 mt-4">
                    Thank you for letting us help you.
                  </p>
                  <p className="font18">
                    Please provide the following information and we will respond
                    to you shortly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="container mb-5">
          <div className="box-shadow contact-card">
            <div className="row">
              <div className="col-lg-8 col-sm-12">
                <form className="p-5 bg-white sm-px-2">
                  <div className="row my-4">
                    <div className="col-lg-6 col-sm-12">
                      <label className="font13"> First Name*</label>
                      <input
                        type="text"
                        placeholder="Enter your first name"
                        className="form-control bg-grey common-input"
                        onChange={(e) => {
                          this.handleChange(e, "fname");
                        }}
                        value={this.state.fname}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 sm-mt">
                      <label className="font13"> Last Name*</label>
                      <input
                        type="text"
                        placeholder="Enter your last name"
                        className="form-control bg-grey common-input"
                        onChange={(e) => {
                          this.handleChange(e, "lname");
                        }}
                        value={this.state.lname}
                      />
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="col-12">
                      <label className="font13"> Email Address*</label>
                      <input
                        type="text"
                        placeholder="Enter your email address"
                        className="form-control bg-grey common-input"
                        onChange={(e) => {
                          this.handleChange(e, "email");
                        }}
                        value={this.state.email}
                      />
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="col-lg-6 col-sm-12">
                      <label className="font13"> Phone Number*</label>
                      <input
                        type="text"
                        placeholder="Enter your phone number with country code"
                        className="form-control bg-grey common-input"
                        value={this.state.phoneNo}
                        onChange={(e) => {
                          this.handleChange(e, "phoneNo");
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12 sm-mt">
                      <label className="font13"> What do you need?</label>
                      <input
                        className="form-control bg-grey common-input"
                        type="text"
                        placeholder={INPUT}
                        onChange={(e) => {
                          this.handleChange(e, "need");
                        }}
                        defaultValue={this.state.need}
                      />
                    </div>
                  </div>
                  {/* <div className="row my-4">
                  <div className="col-12">
                    <label className="font13"> Select Country*</label>
                    <select type="text" placeholder="Select Country*" className="form-control bg-grey common-input">
                      <option value="">Select Country*</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Kenya">Kenya</option>
                      </select>
                  </div>
                </div> */}
                  <div className="row my-4">
                    <div className="col-12">
                      <label className="font13"> Message*</label>
                      <textarea
                        type="text"
                        className="form-control bg-grey common-input"
                        placeholder="Write to us what you would want us to know....."
                        value={this.state.message}
                        onChange={(e) => {
                          this.handleChange(e, "message");
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-right">
                    <div
                      className="btn common-btn bg-blue"
                      onClick={() => {
                        this.sendRequest();
                      }}
                    >
                      Send
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-4 col-sm-12 bg-grey">
                <div className="p-5">
                  <h3 className="font20 font-bold my-4">Contact Information</h3>
                  <div className="d-flex">
                    <div>
                      <img src={Locate} alt="" className="mr-3" />
                    </div>
                    <p className="font16 font-light mb-4">
                      Phoenix, AZ
                      <br />
                      United States
                    </p>
                  </div>
                  {/* <div className="d-flex">
                    <div>
                      <img src={Phone} alt="" className="mr-3" />
                    </div>
                    <p className="font16 font-light mb-5">+2349084884444</p>
                  </div> */}
                  <div className="d-flex">
                    <div>
                      <img src={Mail} alt="" className="mr-3" />
                    </div>
                    <p className="font16 font-light mb-5">
                      <a href="mailto:info@benevolence.care">
                        info@benevolence.care
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }

  handleChange = (e, inputValue) => {
    this.setState({ [inputValue]: e.target.value });
  };
}
