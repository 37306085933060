import React, { Component } from "react";
import Logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import DonateModal from "./donateModal";

export default class navbar extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light pt-5">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="Benevolence" className="w-100" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav ml-auto">
              <Link className="nav-item nav-link active" to="/">
                Home
              </Link>
              <Link className="nav-item nav-link" to="/testimonies">
                Testimonies
              </Link>
              <Link className="nav-item nav-link" to="/contact">
                Contact Us
              </Link>
              <a
                href="https://gf.me/u/xwq8td"
                alt="donate"
                className="nav-item nav-link common-btn btn lg-ml"
                // data-toggle="modal"
                // data-target="#donateModal"
              >
                Donate Now
              </a>
            </div>

            {/* <!-- Modal --> */}
            <div
              className="modal fade"
              id="donateModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="donateModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <DonateModal></DonateModal>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
