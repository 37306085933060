import React from "react";
import "./App.css";
import Home from "./pages/home";
import Footer from "./components/footer";
import Contact from "./pages/contact";
import Request from "./pages/request";

import Testimonies from "./pages/testimonies";
import { BrowserRouter, Route, Switch } from "react-router-dom";

function App() {
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <div className="body">
            <div>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/request" component={Request} />
                <Route exact path="/testimonies" component={Testimonies} />
              </Switch>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
