import React, { Component } from "react";

export default class footer extends Component {
  render() {
    return (
      <footer className="container">
          <hr/>
        <div className="row pt-1">
          <div className="col-lg-6 col-sm-12">Benevolence</div>
          <div className="col-lg-6 col-sm-12 text-right sm-text-left">
            Powered by the  
                <i className='fa fa-heart mx-1'></i>
             of volunteers who desire solely to do good.
          </div>
        </div>
      </footer>
    );
  }
}
